<script setup lang="ts">
const { isDesktop, isIos } = useDevice();
const title = useState("pageTitle", () => "FLIK");
const keywords = useState("pageKeywords", () => "flik, checkout, nuxt");
const route = useRoute();
const trx = useTransactionStore();
const rName: any = route.name || "";
if (hasLength(rName)) {
  title.value = strCapitalized(rName);
}

onMounted(() => {
  console.log("rName:", rName);
});
</script>
<template>
  <Head>
    <Title>{{ title }}</Title>
    <Meta name="description" :content="title" />
    <Meta name="keywords" :content="keywords" />
    <Meta
      v-if="isIos"
      name="viewport"
      content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
    />
  </Head>
  <LazyHeader class="max-w-full p-[16px]" />
  <section id="defaultSection" class="relative max-w-full scrollbar-hide">
    <div
      id="defaultContainer"
      class="relative z-1 h-full"
      :class="[isDesktop ? 'lg:container mx-auto' : '']"
    >
      <slot />
    </div>
    <LazyModalHeadless
      :show="trx.isLoading"
      :line="false"
      :show-close="false"
      :close-outside="false"
      :class-panel="'bg-transparent'"
    >
      <template #info>
        <div class="items-center text-center">
          <LazyIconsLoadingSpinner class="inline text-white" />
          <span class="ml-3 font-medium text-flk-skyblue text-base"
            >Processing transaction...</span
          >
        </div>
      </template>
    </LazyModalHeadless>
  </section>
  <LazyFooter class="w-full p-[16px] flex-row" />
</template>
